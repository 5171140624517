<template>
  <div class="film fullscreen">
    <vue-plyr ref="plyr" :options="playerOptions">
      <video
        id="video"
        controls
        crossorigin
        playsinline
        autoplay
        :data-poster="`${dir}${video}.jpg`"
        ref="video"
      >
        <source :src="`${dir}${video}.mp4`" type="video/mp4" />
      </video>
    </vue-plyr>
    <button
      v-if="isFullscreen"
      @click="closeFilm"
      class="close-button button"
      title="Close film"
    />
  </div>
</template>

<script>
import store from "@/store";
import { CLOSE_VIDEO_MODAL } from "@/store/handlers";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      player: null,
      dir: "https://nickjeffrey.com/files/mp4/",
    };
  },
  watch: {
    fullscreenEscape(val) {
      if (val) {
        if (this.player.fullscreen.active) {
          this.player.fullscreen.exit();
        } else store.dispatch(CLOSE_VIDEO_MODAL);
      }
    },
  },
  computed: {
    ...mapState(["fullscreenEscape", "fullscreenSupport", "video"]),
    ...mapGetters(["isFullscreen"]),
    playerOptions() {
      let options = {
        controls: ["play", "progress", "current-time", "mute", "volume"],
        keyboard: {
          global: true,
        },
        invertTime: false,
      };
      if (!this.isFullscreen) options.controls.push("fullscreen");
      return options;
    },
  },
  methods: {
    closeFilm() {
      store.dispatch(CLOSE_VIDEO_MODAL);
    },
  },
  mounted() {
    this.player = this.$refs.plyr.player;
    this.player.on("exitfullscreen", () => {
      this.closeFilm();
    });
    this.player.on("ready", () => {
      if (!this.isFullscreen) this.player.fullscreen.enter();
    });
  },
};
</script>
